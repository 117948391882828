import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "../../state/store";
import { DataTable } from "../../components/DataTable/DataTable";
import { TournamentHeader } from "../../components/TournamentComponents/TournamentHeader";
import { DateInput, StringInput } from "../../components/Inputs";
import {
  getTournamentsTopPages,
  getTournamentsUsage,
  setTournamentsUsage,
} from "../../state/actions/tournamentActions";
import dayjs from "dayjs";
import { Row } from "../../components/Row";
import { Check } from "../../components/Check/Check";

export function TournamentsUsage() {
  const dispatch = useDispatch();
  const { tournamentsUsage, selectedPage } = useSelector(
    (state: RootStore) => state.tournaments
  );

  const [pageId, setPageId] = useState(selectedPage?.pageId || "");
  const [secondaryId, setSecondaryId] = useState(
    selectedPage?.secondaryId || ""
  );
  const [startDate, setStartDate] = useState(
    dayjs().subtract(30, "days").format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState(dayjs().format("YYYY-MM-DD"));

  const [dateRangeAllPages, setDateRangeAllPages] = useState(true);
  const [onePage, setOnePage] = useState(true);

  return (
    <div className="page">
      <TournamentHeader
        pageName="Tournaments Usage"
        navigateBack="/tournaments"
      />
      <DateInput
        caption="Start Date"
        value={startDate}
        onChange={setStartDate}
      />
      <DateInput caption="End Date" value={endDate} onChange={setEndDate} />
      <Check checked={dateRangeAllPages} onClick={setDateRangeAllPages}>
        Use Date Range for All Pages
      </Check>
      <StringInput caption="Page ID" value={pageId} onChange={setPageId} />
      <StringInput
        caption="Secondary ID"
        value={secondaryId}
        onChange={setSecondaryId}
      />
      <Row style={{ marginTop: 10 }}>
        <button
          className="clickable"
          onClick={() => {
            dispatch(setTournamentsUsage([]));
            dispatch(
              getTournamentsUsage(pageId, secondaryId, startDate, endDate)
            );
            setOnePage(true);
          }}
          style={{ width: 250 }}
        >
          Get Views for This Page
        </button>
        <button
          className="clickable"
          onClick={() => {
            dispatch(setTournamentsUsage([]));
            dispatch(
              dateRangeAllPages
                ? getTournamentsTopPages(startDate, endDate)
                : getTournamentsTopPages()
            );
            setOnePage(false);
          }}
          style={{ width: 250, marginLeft: 15 }}
        >
          Get Views for All Pages
        </button>
      </Row>

      <DataTable<any>
        data={tournamentsUsage}
        columns={[
          {
            title: "Date",
            property: "date",
            render: (obj) => obj.date,
            visible: onePage,
          },
          {
            title: "Page ID",
            property: "pageId",
            render: (obj) => obj.pageId,
            visible: !onePage,
          },
          {
            title: "Secondary ID",
            property: "secondaryId",
            render: (obj) => obj.secondaryId,
            visible: !onePage,
          },
          {
            title: "Page View Count",
            property: "count",
            render: (obj) => obj.count,
          },
        ]}
        style={{ maxWidth: 600, marginTop: 20 }}
        getKey={(obj) => obj.date}
      />
    </div>
  );
}
