import dayjs from "dayjs";
import { Dispatch } from "redux";
import {
  Event,
  Quiz,
  QuizSummary,
  Season,
  SeasonSummary,
  StatsSettings,
} from "../../types/sk";
import { arrayToMap } from "../../util/generalUtil";
import {
  deleteLocalQuiz,
  deleteLocalSeason,
  getLocalQuiz,
  getLocalSeason,
  getLocalSeasonSummaries,
  setLocalQuiz,
  setLocalSeason,
  setLocalSeasonSummaries,
} from "../../util/skUtils";
import {
  AllSKStats,
  SetSeasonSummaries,
  SetSKQuiz,
  SetSKSaveLoading,
  SetSKSeason,
  SetSKStats,
  SET_SK_QUIZ,
  SET_SK_SAVE_LOADING,
  SET_SK_SEASON,
  SET_SK_SEASON_SUMMARIES,
  SET_SK_STATS,
  SKReducerTypes,
} from "../reducers/sk";
import { ThunkActionType } from "../store";
import { fetchWrapper } from "./fetchWrapper";

export const getSeasonList =
  (includeRq: boolean = true): ThunkActionType =>
  async (dispatch: Dispatch<SKReducerTypes>) => {
    dispatch(setSKRequestLoading(true));
    let seasons: SeasonSummary[] = [];
    if (includeRq) {
      const data = await fetchWrapper<{ seasonSummaries: SeasonSummary[] }>(
        dispatch,
        "GET",
        "scorekeeper/readseasonlist"
      );
      if (data.returnCode === 0) seasons = data.responseMessage.seasonSummaries;
    }
    const localSeasons: SeasonSummary[] = getLocalSeasonSummaries().map(
      (season) => ({ ...season, location: "LOCAL" })
    );
    const seasonMap = arrayToMap(
      (season) => season.seasonId,
      localSeasons,
      seasons
    );

    dispatch(setSeasonList(Object.values(seasonMap)));
  };

export const createSeason =
  (season: Season, runRq: boolean = true): ThunkActionType =>
  async (dispatch: Dispatch<SKReducerTypes>) => {
    dispatch(setSKRequestLoading(true));
    const createLocally = async () => {
      const summaries = getLocalSeasonSummaries();
      if (!summaries.some((sum) => sum.seasonId === season.seasonId))
        summaries.push({
          seasonId: season.seasonId,
          seasonName: season.seasonName,
          isPractice: season.isPractice,
          location: "LOCAL",
          lastUpdated: season.lastUpdated,
        });
    };
    await createLocally();
    let dbSeason: Season | undefined = undefined;
    if (runRq) {
      const res = await fetchWrapper(
        dispatch,
        "PUT",
        "scorekeeper/createseason",
        {
          ...season,
          events: season.events.map((ev) => ({
            ...ev,
            quizzes: undefined,
          })),
        }
      );
      if (res.returnCode === 0) {
        dbSeason = res.responseMessage.season;
      }
    }
    dispatch(setSelectedSeason(compareSeasonStores(season, dbSeason)));
  };

export const updateSeason =
  (season: Season, runRq: boolean = true): ThunkActionType =>
  async (dispatch: Dispatch<SKReducerTypes>) => {
    dispatch(setSKRequestLoading(true));
    const saveLocal = async () => {
      setLocalSeason(season);
    };
    await saveLocal();
    if (runRq) {
      const result = await fetchWrapper(
        dispatch,
        "PATCH",
        "scorekeeper/updateseason",
        {
          season: {
            ...season,
            events: season.events.map((ev) => ({
              ...ev,
              quizzes: undefined,
            })),
          },
        }
      );
      dispatch(
        setSelectedSeason({
          ...season,
          location: result?.returnCode === 0 ? "DB" : "LOCAL",
        })
      );
    } else {
      dispatch(setSelectedSeason({ ...season, location: "LOCAL" }));
    }
  };

export const deleteSeason =
  (seasonId: string, runRq: boolean = true): ThunkActionType =>
  async (dispatch: Dispatch<SKReducerTypes>) => {
    dispatch(setSKRequestLoading(true));
    const deleteLocally = async () => {
      deleteLocalSeason(seasonId);
      const summaries = getLocalSeasonSummaries().filter(
        (sum) => sum.seasonId !== seasonId
      );
      setLocalSeasonSummaries(summaries);
      return summaries;
    };
    const newList = await deleteLocally();
    if (runRq)
      await fetchWrapper(dispatch, "DELETE", "scorekeeper/deleteseason", {
        seasonId,
      });
    dispatch(setSelectedSeason());
    dispatch(setSeasonList(newList));
    dispatch(setSKRequestLoading(false));
  };

export const getSeasonInfo = async (
  seasonId: string
): Promise<Season | undefined> => {
  const data = await fetchWrapper(
    undefined,
    "GET",
    `scorekeeper/getseasondetails/${seasonId}`
  );
  let dbSeason: Season | undefined = data?.responseMessage?.season;
  const localSeason = getLocalSeason(seasonId);
  return compareSeasonStores(localSeason, dbSeason);
};

const compareSeasonStores = (localSeason?: Season, dbSeason?: Season) => {
  if (!dbSeason || !localSeason) return dbSeason || localSeason;

  // Combine local and db data
  const prioritizeLocal =
    localSeason.lastUpdated &&
    localSeason.lastUpdated !== dbSeason.lastUpdated &&
    dayjs(localSeason.lastUpdated) > dayjs(dbSeason.lastUpdated);
  const resultSeason = prioritizeLocal ? localSeason : dbSeason;
  if (prioritizeLocal) {
    if (dbSeason) resultSeason.location = "BEHIND";
    else resultSeason.location = "LOCAL";
  }

  // map events and quizzes from the season we're using
  const eventMap: Record<string, Event> = {};
  const quizMap: Record<string, QuizSummary> = {};
  (prioritizeLocal ? localSeason : dbSeason).events.forEach((ev) => {
    if (ev.eventId) {
      eventMap[ev.eventId] = ev;
      ev.quizzes.forEach((q) => {
        quizMap[q.quizId] = q;
      });
    }
  });
  // Look through the other season and add missing data. We don't worry about timestamps for quizzes since those are managed elsewhere
  (prioritizeLocal ? dbSeason : localSeason).events.forEach((ev) => {
    if (eventMap[ev.eventId]) {
      // check quizzes inside
      ev.quizzes?.forEach((q) => {
        if (!quizMap[q.quizId]) {
          // add quiz to the right event
          const seasonEvent = resultSeason.events.find(
            (sEvent) => sEvent.eventId === ev.eventId
          );
          if (seasonEvent) seasonEvent.quizzes.push(q);
        }
      });
    } else {
      // add event
      if (ev.eventId) resultSeason.events.push(ev);
    }
  });
  return resultSeason;
};

export const createQuiz =
  (
    quiz: Quiz,
    runRq: boolean = true,
    currentSeason?: Season
  ): ThunkActionType =>
  async (dispatch: Dispatch<SKReducerTypes>) => {
    dispatch(setSKRequestLoading(true));
    const season = currentSeason || getLocalSeason(quiz.seasonId);
    const performCreate = async () => {
      setLocalQuiz(quiz);
      if (season) {
        const event = season.events.find((ev) => ev.eventId === quiz.eventId);
        if (event) {
          const summary: any = {
            ...quiz,
          };
          delete summary.moments;
          delete summary.sharedWith;
          delete summary.location;
          delete summary.watchability;

          if (!event.quizzes.some((q) => q.quizId === quiz.quizId))
            event.quizzes.push(summary);
          setLocalSeason(season);
        }
      }
    };
    await performCreate();
    dispatch(setSelectedQuiz(quiz));
    dispatch(setSelectedSeason(season));
    if (runRq)
      fetchWrapper(dispatch, "PUT", "scorekeeper/createquiz", {
        quiz,
      });
  };

export const updateQuiz =
  (quiz: Quiz, includeRq: boolean = true): ThunkActionType =>
  async (dispatch: Dispatch<SKReducerTypes>) => {
    setLocalQuiz(quiz);
    dispatch(setSelectedQuiz(quiz));
    if (includeRq)
      fetchWrapper(dispatch, "PATCH", "scorekeeper/updatequiz", {
        quiz,
      });
  };

export const getQuiz = async (
  quizId: string,
  watching: boolean = false
): Promise<Quiz | undefined> => {
  const data = await fetchWrapper(
    undefined,
    "GET",
    `scorekeeper/${watching ? "getwatchablequiz" : "readquiz"}/${quizId}`
  );
  if (watching) return data?.responseMessage?.quiz;
  const local = getLocalQuiz(quizId);
  const db = data?.responseMessage?.quiz;
  let result: Quiz | undefined = undefined;
  if (!local || !db) {
    result = local || db;
  } else {
    // Use newer copy of the data, unless one has more moments
    if (local.moments?.length > db.moments.length) {
      result = { ...local, location: "BEHIND" };
    } else if (db.moments.length > local.moments.length) {
      result = db;
    } else {
      result =
        local.lastUpdated && dayjs(local.lastUpdated) > dayjs(db.lastUpdated)
          ? { ...local, location: "BEHIND" }
          : db;
    }
  }
  if (result) {
    if (result.location === "DB") setLocalQuiz(result);
  }
  return result;
};

export const deleteQuiz =
  (
    quizId: string,
    eventId: string,
    seasonId: string,
    runRq: boolean = false
  ): ThunkActionType =>
  async (dispatch: Dispatch<SKReducerTypes>) => {
    dispatch(setSKRequestLoading(true));
    const season = getLocalSeason(seasonId);
    const deleteLocally = async () => {
      deleteLocalQuiz(quizId);
      if (!season) return;
      const event = season.events.find((ev) => ev.eventId === eventId);
      if (event) {
        event.quizzes = event.quizzes.filter((q) => q.quizId !== quizId);
      }
      setLocalSeason(season);
    };
    await deleteLocally();
    if (runRq) {
      await fetchWrapper(dispatch, "DELETE", "scorekeeper/deletequiz", {
        quizId,
      });
    }
    dispatch(setSelectedSeason());
  };

export const getStats =
  (
    seasonId: string,
    statsType: keyof AllSKStats,
    settings: StatsSettings
  ): ThunkActionType =>
  async (dispatch: Dispatch<SKReducerTypes>) => {
    dispatch(setSKRequestLoading(true));
    dispatch(setScorekeeperStats({ [statsType]: undefined })); // Clear stats
    const data = await fetchWrapper(dispatch, "POST", "scorekeeper/getstats", {
      statsType,
      settings,
      seasonId,
    });
    if (data.returnCode === 0)
      dispatch(
        setScorekeeperStats({ [statsType]: data.responseMessage.stats })
      );
  };

export const getImportableTeamSets = async () => {
  const response = await fetchWrapper(
    undefined,
    "GET",
    "scorekeeper/getimportableteamsets"
  );
  if (response.returnCode === 0) return response.responseMessage.sets;
  return [];
};

export const addTeamSet =
  (seasonId: string, setId: string): ThunkActionType =>
  async (dispatch: Dispatch<SKReducerTypes>) => {
    dispatch(setSKRequestLoading(true));
    const data = await fetchWrapper(
      dispatch,
      "POST",
      "scorekeeper/importteamset",
      {
        seasonId,
        setId,
      }
    );
    if (data.returnCode === 0)
      dispatch(setSelectedSeason(data.responseMessage.season));
  };

export const getImportableQuizSets = async (teamSetId: string) => {
  const response = await fetchWrapper(
    undefined,
    "GET",
    `scorekeeper/getimportableevents/${teamSetId}`
  );
  if (response.returnCode === 0) return response.responseMessage.events;
  return [];
};

export const addImportableEvent =
  (seasonId: string, eventName: string, myTeam: string): ThunkActionType =>
  async (dispatch: Dispatch<SKReducerTypes>) => {
    dispatch(setSKRequestLoading(true));
    const data = await fetchWrapper(
      dispatch,
      "POST",
      "scorekeeper/importquizset",
      {
        seasonId,
        eventName,
        myTeam,
      }
    );
    if (data.returnCode === 0)
      dispatch(setSelectedSeason(data.responseMessage.season));
  };

export const getEventsForTime = async (
  account: string
): Promise<{ eventName: string; eventId: number }[]> => {
  const response = await fetchWrapper(
    undefined,
    "GET",
    `scorekeeper/geteventsfortimer/${account}`
  );
  return response.responseMessage.events || [];
};
export const getQuizIdForTime = async (
  account: string,
  event: number | string,
  round: number,
  site: number
): Promise<number> => {
  const response = await fetchWrapper(
    undefined,
    "GET",
    `scorekeeper/getquizfortimer/${account}/${event}/${round}/${site}`
  );
  return response.responseMessage.quizId || 0;
};

export const setSKRequestLoading = (isLoading: boolean): SetSKSaveLoading => ({
  type: SET_SK_SAVE_LOADING,
  payload: {
    isLoading,
  },
});
export const setSeasonList = (
  summaries: SeasonSummary[]
): SetSeasonSummaries => ({
  type: SET_SK_SEASON_SUMMARIES,
  payload: {
    summaries,
  },
});
export const setSelectedSeason = (season?: Season): SetSKSeason => ({
  type: SET_SK_SEASON,
  payload: {
    season,
  },
});
export const setSelectedQuiz = (quiz?: Quiz): SetSKQuiz => ({
  type: SET_SK_QUIZ,
  payload: {
    quiz,
  },
});
export const setScorekeeperStats = (stats: AllSKStats): SetSKStats => ({
  type: SET_SK_STATS,
  payload: {
    stats,
  },
});
