import { Component, ErrorInfo, ReactElement, ReactNode } from "react";
import { fetchWrapper } from "../state/actions/fetchWrapper";
import { Row } from "./Row";

interface Props {
  children: ReactNode;
  fallbackContent?: ReactElement;
}

interface State {
  hasError: boolean;
}
export class ErrorHandler extends Component<Props, State> {
  public state: State = {
    hasError: false,
  };
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  public static getDerivedStateFromError(error: Error): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    fetchWrapper(undefined, "POST", "crash", {
      error: JSON.stringify(error, Object.getOwnPropertyNames(error)),
      page: document.location.href,
    });
    this.setState({
      ...this.state,
      hasError: true,
    });
  }

  public render() {
    if (this.state.hasError) {
      if (this.props.fallbackContent) return this.props.fallbackContent;
      return (
        <div className="page">
          <h1 style={{ color: "red", fontSize: 18 }}>Uh oh...</h1>
          <p>An error occurred and your session will have to be restarted.</p>
          <p>
            If the problem persists, please send a detailed bug report to&nbsp;
            <a href="mailto:peter@biblequizacademy.com">
              peter@biblequizacademy.com
            </a>
            .
          </p>
          <Row>
            <button
              className="clickable"
              onClick={() => {
                window.location.reload();
              }}
            >
              Refresh
            </button>
            <a
              className="clickable"
              style={{
                marginLeft: 20,
              }}
              href="/"
            >
              Return Home
            </a>
          </Row>
        </div>
      );
    }

    return this.props.children;
  }
}
