import { useEffect } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { useDispatch } from "react-redux";
import { fetchWrapper } from "../state/actions/fetchWrapper";
import { MainMenu } from "./MainMenu/MainMenu";
import { QuizNow } from "./QuizNow";
import { VerseCards } from "./VerseCards";
import {
  EditEvents,
  EditSeasons,
  EditTeams,
  SKMenu,
  QuizSite,
  NewQuiz,
  QuizzerStats,
  TeamStats,
  SKHelp,
  WatchQuiz,
  SingleQuizzerStats,
  SingleTeamStats,
  ChapterStats,
  SingleChapterStats,
  EditHotkeys,
} from "./Scorekeeper";
import {
  MultiplayerHome,
  MultiplayerIndividual,
  MultiplayerTeams,
} from "./Multiplayer";
import {
  TournamentHome,
  CreateTournamentPage,
  EditTournamentWrapper,
  TournamentPageWrapper,
  TournamentAdmin,
} from "./Tournaments";
import { NewPracticeQuiz } from "./Scorekeeper/NewPracticeQuiz";
import { ResetPassword } from "./Authentication/ResetPassword";
import { SET_APP_DETAILS } from "../state/reducers/appDetails";
import { Login } from "./Authentication/Login";
import { UserInfo } from "./Authentication/UserInfo";
import { SET_LOGOUT } from "../state/actions/authentication";
import { QuizSiteWatching } from "./Scorekeeper/QuizSite";
import { Reaction } from "./Reaction";
import { TournamentContact } from "./Tournaments/TournamentContact";
import { MultiTeamsLobby } from "./Multiplayer/MultiTeamsLobby";
import { MultiIndividualLobby } from "./Multiplayer/MultiIndividualLobby";
import { NoPage } from "./NoPage";
import { TournamentSearch } from "./Tournaments/TournamentSearch";
import { TournamentsUsage } from "./Tournaments/TournamentsUsage";
import { QuestionDrill } from "./QuestionDrill";

const MainRouter = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    fetchWrapper(undefined, "GET", "security/tokenping").then((data) => {
      dispatch({
        type: SET_APP_DETAILS,
        payload: {
          version: data.responseMessage.version || "",
          usage: data.responseMessage.usage || [],
          allWeeksUsage: data.responseMessage.allWeeks || [],
        },
      });
      if (data.returnCode === 0 && !data.responseMessage.validToken) {
        dispatch({ type: SET_LOGOUT });
      }
    });
  }, []);

  return (
    <Routes>
      {/* Authentication */}
      <Route path="/passwordreset/:resetId" element={<ResetPassword />} />
      <Route path="/login" element={<Login />} />
      <Route path="/user" element={<UserInfo />} />

      {/* Singleplayer tools */}
      <Route path="/quiznow" element={<QuizNow />} />
      <Route path="/versecards" element={<VerseCards />} />
      <Route path="/reaction" element={<Reaction />} />
      <Route path="/questiondrill" element={<QuestionDrill />} />

      {/* SK Watch Quiz */}
      <Route path="/watchquiz/:quizId" element={<QuizSiteWatching />} />
      <Route path="/watchquiz" element={<WatchQuiz />} />

      {/* SK */}
      <Route path="/sk/seasons" element={<EditSeasons />} />
      <Route path="/sk/help" element={<SKHelp />} />
      <Route path="/sk/hotkeys" element={<EditHotkeys />} />
      <Route path="/sk/:seasonId/events" element={<EditEvents />} />
      <Route path="/sk/:seasonId/teams" element={<EditTeams />} />
      <Route path="/sk/:seasonId/quizzerstats" element={<QuizzerStats />} />
      <Route
        path="/sk/:seasonId/quizzerstats/:quizzerId"
        element={<SingleQuizzerStats />}
      />
      <Route path="/sk/:seasonId/chapterstats" element={<ChapterStats />} />
      <Route
        path="/sk/:seasonId/chapterstats/:chapterId"
        element={<SingleChapterStats />}
      />
      <Route path="/sk/:seasonId/teamstats" element={<TeamStats />} />
      <Route
        path="/sk/:seasonId/teamstats/:teamId"
        element={<SingleTeamStats />}
      />
      <Route path="/sk/:seasonId/:eventId/newquiz" element={<NewQuiz />} />
      <Route
        path="/sk/:seasonId/:eventId/newpracticequiz"
        element={<NewPracticeQuiz />}
      />
      <Route path="/sk/:seasonId/:eventId/:quizId" element={<QuizSite />} />
      <Route path="/sk/:seasonId/:eventId" element={<SKMenu />} />
      {/* Fallback SK Route */}
      <Route path="/sk" element={<Navigate to="/sk/seasons" />} />

      {/* Tournaments */}
      <Route path="/tournaments">
        <Route index element={<TournamentHome />} />
        <Route path="create" element={<CreateTournamentPage />} />
        <Route path="contact" element={<TournamentContact />} />
        <Route path="admin" element={<TournamentAdmin />} />
        <Route path="usage" element={<TournamentsUsage />} />
        <Route path="search/:searchText?" element={<TournamentSearch />} />
        <Route
          path="edit/:pageId/:secondaryId?"
          element={<EditTournamentWrapper />}
        />
        <Route
          path=":pageId/:secondaryId?"
          element={<TournamentPageWrapper />}
        />
      </Route>

      {/* Multiplayer */}
      <Route path="/multiplayer">
        <Route index element={<MultiplayerHome />} />
        <Route path="individual">
          <Route index element={<MultiIndividualLobby />} />
          <Route path=":roomId" element={<MultiplayerIndividual />} />
        </Route>
        <Route path="teams">
          <Route path=":roomId?" element={<MultiTeamsLobby />} />
          <Route path=":roomId/quiz" element={<MultiplayerTeams />} />
        </Route>
      </Route>

      {/* Main Menu */}
      <Route path="/" element={<MainMenu />} />

      {/* Fallthrough Route - redirect to the route that sends you to the main menu */}
      <Route path="*" element={<NoPage />} />
    </Routes>
  );
};

export default MainRouter;
