import React, { useState } from "react";
import { Navigate, useLocation, useNavigate, Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootStore } from "../../../state/store";
import { Row } from "../../../components/Row";
import { DataTable } from "../../../components/DataTable/DataTable";
import { Check } from "../../../components/Check/Check";
import { QuestionsJumpsGraphs } from "./QuestionsJumpsGraphs";
import { useDocumentTitle } from "../../../hooks/useDocumentTitle";
import { useKeyedParams } from "../../../hooks/useParams";
import { getJumpType } from "../../../util/skStatsUtils";
import * as skTypes from "../../../types/sk";
import "../SK.scss";

interface StatsProps {
  seasonId: string;
  quizzerId: string;
}

export function SingleQuizzerStats() {
  useDocumentTitle("Quizzer Stats - Bible Quiz Academy");
  const location = useLocation();
  const navigate = useNavigate();
  const { seasonId, quizzerId } = useKeyedParams<StatsProps>();
  const { material } = useSelector((state: RootStore) => state.material);

  const stats: skTypes.QuizzerStats | undefined = location.state?.quizzerStats;

  const [rowSelected, setRowSelected] = useState<
    skTypes.MomentInQuiz | undefined
  >(undefined);
  const detailedStatsView: skTypes.DetailedStatsOptions =
    location.state?.detailedStatsView ||
    (quizzerId === "total" ? "NONE" : "BY_CHAPTER");
  const setDetailedStatsView = (view: skTypes.DetailedStatsOptions) => {
    navigate(location.pathname, {
      state: {
        ...location.state,
        detailedStatsView: view,
      },
      replace: true,
    });
    setRowSelected(undefined);
  };

  if (!stats) {
    return (
      <Navigate to={`/sk/${seasonId}/quizzerstats`} state={location.state} />
    );
  }

  const questionsByChapter: Record<string, skTypes.Moment[]> = {}; // Key is `${book}-${chapter}`
  stats.questions.forEach((question) => {
    if (
      question.moment.type === "TWENTY_POINTS" ||
      question.moment.type === "ERROR"
    ) {
      const key =
        question.moment.book !== undefined &&
        question.moment.chapter !== undefined
          ? `${question.moment.book}-${question.moment.chapter}`
          : "99-0";
      if (questionsByChapter[key]) {
        questionsByChapter[key].push(question.moment);
      } else {
        questionsByChapter[key] = [question.moment];
      }
    }
  });

  const tableKeys = Object.keys(questionsByChapter)
    .sort((key1, key2) => {
      const parts1 = key1.split("-");
      const parts2 = key2.split("-");
      const book1 = Number.parseInt(parts1[0]);
      const book2 = Number.parseInt(parts2[0]);
      if (book1 !== book2) return book1 - book2;
      const chapter1 = Number.parseInt(parts1[1]);
      const chapter2 = Number.parseInt(parts2[1]);
      return chapter1 - chapter2;
    })
    .map((key) => {
      const parts = key.split("-");
      const book = Number.parseInt(parts[0]);
      const chapter = Number.parseInt(parts[1]);

      const questions: skTypes.Moment[] = questionsByChapter[key];
      let correct = 0,
        incorrect = 0,
        kErrors = 0,
        questionsWithWords = 0;
      questions.forEach((question: skTypes.Moment) => {
        switch (question.type) {
          case skTypes.tp:
            correct += 1;
            break;
          case skTypes.error:
            incorrect += 1;
            break;
        }
        if (
          question.type === "ERROR" &&
          (question.errorType === "K" || question.errorType === "L")
        )
          kErrors += 1;
        const jumpType = getJumpType(question);
        if (
          jumpType &&
          [skTypes.multipleWords, skTypes.oneWord, skTypes.read].includes(
            jumpType
          )
        )
          questionsWithWords += 1;
      });
      return {
        key,
        chapter:
          book === 99
            ? "--unknown--"
            : `${material[book].bookName} ${chapter + 1}`,
        correct,
        incorrect,
        kErrors,
        word: Math.round((questionsWithWords * 100.0) / questions.length) + "%",
        book,
      };
    });

  const getSelectedRowInfo = (): React.ReactElement | null => {
    if (rowSelected === undefined) return null;
    return (
      <div>
        <h2>Selected Question(s)</h2>
        <Link
          className="link"
          style={{ marginTop: 10, marginLeft: 0, padding: 0 }}
          to={`/sk/${seasonId}/${rowSelected.eventId}/${rowSelected.quizId}`}
        >
          Go To Quiz
        </Link>
      </div>
    );
  };

  const getDetailedViewCheck = (
    view: skTypes.DetailedStatsOptions,
    title: string
  ) => (
    <Check
      checked={detailedStatsView === view}
      onClick={() => setDetailedStatsView(view)}
      style={{ width: 200 }}
    >
      {title}
    </Check>
  );
  return (
    <div className="page">
      <Row>
        <button className="back-button" onClick={() => navigate(-1)}>
          Go Back
        </button>
        <h1
          style={{ marginLeft: 10 }}
        >{`Quizzer Stats - ${stats.quizzerName}`}</h1>
      </Row>
      <QuestionsJumpsGraphs
        questionsByType={stats.questionsByType}
        jumpsByType={stats.jumpsByType}
        knowledgeErrors={stats.knowledgeErrorsByType}
        additionalRowContent={
          rowSelected !== undefined ? getSelectedRowInfo() : undefined
        }
      />
      {detailedStatsView !== "NONE" && (
        <>
          <h2 style={{ marginTop: 20, marginBottom: 10 }}>
            Select a view for stats below:
          </h2>

          <Row>
            {getDetailedViewCheck("BY_CHAPTER", "Jumps By Chapter")}
            {getDetailedViewCheck("ALL_JUMPS", "All Jumps")}
            {getDetailedViewCheck("K_ERRORS", "Knowledge Errors")}
            {getDetailedViewCheck("BY_QUIZ", "By Quiz")}
          </Row>
        </>
      )}
      {detailedStatsView === "BY_CHAPTER" && (
        <DataTable
          style={{
            maxHeight: 500,
          }}
          columns={[
            {
              title: "Chapter",
              property: "chapter",
              render: (obj) => obj.chapter,
            },
            {
              title: "Correct",
              property: "correct",
              render: (obj) => obj.correct,
            },
            {
              title: "Incorrect",
              property: "incorrect",
              render: (obj) => obj.incorrect,
            },
            {
              title: "K-Errors",
              property: "kErrors",
              render: (obj) => obj.kErrors,
            },
            {
              title: "Word %",
              property: "word",
              render: (obj) => (obj.book === 99 ? "-" : obj.word),
            },
          ]}
          getKey={(obj) => obj.key}
          data={tableKeys}
        />
      )}
      {["ALL_JUMPS", "K_ERRORS"].includes(detailedStatsView) && (
        <DataTable<skTypes.MomentInQuiz>
          style={{
            maxHeight: 500,
          }}
          getKey={(question) => `${question.quizId}-${question.qIndex}`}
          columns={[
            {
              title: "Quiz",
              property: "quiz",
              render: (question) => {
                let quizName: string = question.eventName + " ";
                if (question.quizName) {
                  quizName += question.quizName;
                } else {
                  if (question.round) quizName += `R${question.round} `;
                  if (question.site) quizName += `S${question.site} `;
                }
                return quizName;
              },
            },
            {
              title: "Reference",
              property: "reference",
              render: (question) => {
                let reference: string = "";
                if (
                  "book" in question.moment &&
                  question.moment.book !== undefined
                )
                  reference += material[question.moment.book].bookName + " ";
                if (
                  "chapter" in question.moment &&
                  question.moment.chapter !== undefined
                )
                  reference += question.moment.chapter + 1;
                if (
                  "verse" in question.moment &&
                  question.moment.verse !== undefined
                )
                  reference += ":" + (question.moment.verse + 1);
                return reference;
              },
            },
            {
              title: "Question",
              property: "question",
              render: (question) => {
                const questionText =
                  "question" in question.moment
                    ? question.moment.question || ""
                    : "";
                const receivedCharacters =
                  "receivedCharacters" in question.moment
                    ? question.moment.receivedCharacters || 0
                    : 0;
                return (
                  <>
                    <span className="received-characters">
                      {questionText.substring(0, receivedCharacters)}
                    </span>
                    {receivedCharacters !== undefined && (
                      <span>
                        {questionText.substring(receivedCharacters || 0)}
                      </span>
                    )}
                    {"read" in question.moment && question.moment.read && (
                      <span>&nbsp;[R]</span>
                    )}
                  </>
                );
              },
            },
            {
              title: "Choices",
              property: "choices",
              render: (question) => {
                const moment = question.moment;
                if (moment.type !== "TWENTY_POINTS" && moment.type !== "ERROR")
                  return "";

                let choices: string = "-";
                if (
                  moment.question?.startsWith("Quote?") &&
                  (moment.receivedCharacters === undefined ||
                    moment.receivedCharacters > 0)
                ) {
                  choices = "1";
                } else if (
                  moment.book !== undefined &&
                  moment.chapter !== undefined &&
                  moment.verse !== undefined
                ) {
                  if (moment.receivedCharacters === -1) {
                    choices = "Split";
                  } else {
                    const start = (moment.question || "").substring(
                      0,
                      moment.receivedCharacters
                    );
                    let choiceResult = 0;
                    material[moment.book]?.chapters[moment.chapter]?.[
                      moment.verse
                    ].questions.forEach((verseQuestion) => {
                      if (verseQuestion.startsWith(start)) choiceResult++;
                    });
                    choices = String(choiceResult);
                    if (!moment.question || moment.receivedCharacters === 0)
                      choices = `${choices} (+Q)`;
                  }
                }
                return choices;
              },
            },
            {
              title:
                detailedStatsView === "K_ERRORS" ? "K-Error Type" : "Result",
              property: "result",
              render: (question) => {
                let result: string =
                  skTypes.momentTypeDescriptions[question.moment.type];
                if (question.moment.type === "ERROR") {
                  if (question.moment.errorType)
                    result =
                      skTypes.errorDescription[question.moment.errorType];
                  if (question.moment.errorType === "K") {
                    if (question.moment.kErrorType)
                      result = `${result} (${
                        skTypes.kErrorDescription[question.moment.kErrorType]
                      })`;
                    if (
                      detailedStatsView === "K_ERRORS" &&
                      question.moment.kErrorType
                    ) {
                      result =
                        skTypes.kErrorDescription[question.moment.kErrorType];
                    }
                  }
                }
                return result;
              },
            },
          ]}
          data={
            detailedStatsView === "ALL_JUMPS"
              ? stats.questions
              : stats.questions.filter(
                  (q) =>
                    q.moment.type === "ERROR" &&
                    q.moment.errorType === skTypes.knowledge
                )
          }
          onClickCell={(obj) => {
            setRowSelected(
              obj.quizId === rowSelected?.quizId ? undefined : obj
            );
          }}
          rowDisplay={(obj) => ({
            selected: obj.quizId === rowSelected?.quizId,
          })}
        />
      )}
      {detailedStatsView === "BY_QUIZ" && (
        <DataTable
          style={{
            maxHeight: 500,
          }}
          getKey={(obj) => obj.quizId}
          columns={[
            {
              title: "Quiz",
              property: "quizName",
              render: (obj) => {
                let quizName: string = obj.eventName + " ";
                if (obj.quizName) {
                  quizName += obj.quizName;
                } else {
                  if (obj.round) quizName += `R${obj.round} `;
                  if (obj.site) quizName += `S${obj.site} `;
                }
                return quizName;
              },
            },
            {
              title: "Teams",
              property: "teams",
              render: (obj) => obj.teams,
            },
            {
              title: "Correct",
              property: "correct",
              render: (obj) => obj.correct,
            },
            {
              title: "Errors",
              property: "errors",
              render: (obj) => obj.errors,
            },
          ]}
          data={Object.values(stats.byQuiz)}
        />
      )}
    </div>
  );
}
