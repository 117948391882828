import { useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "../../state/store";
import { CircularProgress } from "@mui/material";
import { TournamentLink } from "../../components/TournamentComponents/TournamentLink";
import { TournamentHeader } from "../../components/TournamentComponents/TournamentHeader";
import { TournamentFooter } from "../../components/TournamentComponents/TournamentFooter";
import { useKeyedParams } from "../../hooks/useParams";
import { tournamentSearch } from "../../state/actions/tournamentActions";
import { useDocumentTitle } from "../../hooks/useDocumentTitle";

export function TournamentSearch() {
  const { searchText } = useKeyedParams<{ searchText?: string }>();
  useDocumentTitle(`${searchText} - BQA Tournaments Search`);
  const dispatch = useDispatch();
  const { isLoading, searchPages } = useSelector(
    (state: RootStore) => state.tournaments
  );
  useEffect(() => {
    if (!isLoading && searchText) dispatch(tournamentSearch(searchText));
  }, []);
  const descriptionDisplayLimit = 140;
  return (
    <div className="page">
      <TournamentHeader
        pageName={searchText ? `Search for ${searchText}` : "Search"}
        navigateBack="/tournaments"
        defaultSearch={searchText || ""}
      />
      {isLoading && <CircularProgress />}
      {!isLoading && searchText && (
        <>
          <h3 style={{ marginBottom: 20 }}>{`${searchPages.length || "No"}${
            searchPages.length >= 50 ? "+" : ""
          } Results for ${searchText}`}</h3>
          <p>
            Don't see the page you're looking for?{" "}
            <Link
              to="/tournaments/contact"
              state={{
                contactInfo: {
                  contactType: "INFO",
                  additionalText: searchText,
                },
              }}
            >
              Contact us
            </Link>{" "}
            to get it added!
          </p>
          {searchPages.map((page) => {
            return (
              <div
                key={`${page.pageId} ${page.secondaryId || ""}`}
                style={{ marginBottom: 20 }}
              >
                <TournamentLink
                  pageId={page.pageId}
                  secondaryId={page.secondaryId}
                />
                <p style={{ margin: "4px 0px" }}>{`${page.description.substring(
                  0,
                  descriptionDisplayLimit
                )}${
                  page.description.length > descriptionDisplayLimit ? "..." : ""
                }`}</p>
              </div>
            );
          })}
        </>
      )}
      <TournamentFooter />
    </div>
  );
}
