import { EnumDescription } from "../types/generalTypes";

/**
 * @param selectedChapters book, chapter: what's selected
 * @returns true iff at least one chapter is selected
 */
export const chapterSelected = (
  selectedChapters: (boolean | boolean[])[][]
): boolean => {
  if (!selectedChapters) return false;
  let result: boolean = false;
  selectedChapters.forEach((book) => {
    if (book) {
      book.forEach((chapter) => {
        if (typeof chapter === "boolean") {
          if (chapter) result = true;
        } else {
          chapter.forEach((verse) => {
            if (verse) result = true;
          });
        }
      });
    }
  });
  return result;
};

/**
 * @param arr an array containing arbitrary values
 * shuffles an arbitrarily array in place. Returns nothing; the array itself is shuffled
 */
export function shuffle<E>(arr: E[]): void {
  for (let i: number = arr.length - 1; i > 0; i--) {
    let j: number = Math.floor(Math.random() * (i + 1));
    let temp = arr[i];
    arr[i] = arr[j];
    arr[j] = temp;
  }
}

/**
 * @param arr an array of booleans
 * @returns true iff all the booleans are true
 */
export const combineBooleans = (arr: (boolean | boolean[])[]): boolean => {
  let result: boolean = true;
  arr?.forEach((item) => {
    if (typeof item === "boolean") {
      if (!item) result = false;
    } else {
      item.forEach((item2) => {
        if (!item2) result = false;
      });
    }
  });
  return result;
};
export function combineBooleansObject<T>(
  obj: Record<string, T>,
  convert: (item: T) => boolean
): boolean {
  let result: boolean = true;
  Object.keys(obj).forEach((key: string) => {
    if (!convert(obj[key])) result = false;
  });
  return result;
}

/**
 * the exclusive or of booleans. Takes an array of any size
 * @returns true iff at least one element is true and at least one element is false
 */
export const xor = (arr: (boolean | boolean[])[]): boolean => {
  let allTrue: boolean = true;
  let allFalse: boolean = true;
  arr?.forEach((item) => {
    if (typeof item === "boolean") {
      if (!item) allTrue = false;
      if (item) allFalse = false;
    } else {
      item.forEach((item2) => {
        if (!item2) allTrue = false;
        if (item2) allFalse = false;
      });
    }
  });
  return !allTrue && !allFalse;
};
export function xorObject<T>(
  obj: Record<string, T>,
  convert: (item: T) => boolean
): boolean {
  let allTrue: boolean = true;
  let allFalse: boolean = true;
  Object.keys(obj).forEach((key: string) => {
    const checked = convert(obj[key]);
    if (!checked) allTrue = false;
    if (checked) allFalse = false;
  });
  return !allTrue && !allFalse;
}

/**
 * Useful when we need a percentage, even when the denomonator might be 0
 * @param num numerator
 * @param den denomonator
 * @returns a number that will not be NaN
 */
export const percentageNumber = (num: number, den: number): number => {
  if (den === 0) return 0;
  return (num * 100.0) / den;
};

export const getPerAmount = (num: number, den: number): string => {
  if (den === 0) return "-";
  return (num / den).toFixed(1);
};

/**
 * @typedef E any type, probably some object
 * @param list a list, or many lists, of objects. If duplicate keys are provided, the last one seen will be used
 * @param getKey how to get the key of the object
 * @returns a map with the list key as the key, and the list items as values
 */
export function arrayToMap<E>(
  getKey: (item: E) => string,
  ...list: E[][]
): Record<string, E> {
  const result: Record<string, E> = {};
  list.forEach((item) => {
    if (Array.isArray(item)) {
      item.forEach((subItem) => {
        result[getKey(subItem)] = subItem;
      });
    } else {
      result[getKey(item)] = item;
    }
  });
  return result;
}

export function arrayToEnum<E>(
  list: E[],
  callback: (item: E, index: number) => { key: string; value: string }
): Record<string, string> {
  const result: Record<string, string> = {};
  list.forEach((item, index) => {
    const value = callback(item, index);
    result[value.key] = value.value;
  });
  return result;
}

export function mapToArray<E extends string>(map: Record<E, unknown>): E[] {
  return Object.keys(map) as E[];
}
export function mapForEach<E>(
  map: Record<string, E>,
  callback: (item: E, key: string) => void
) {
  Object.keys(map).forEach((key) => {
    callback(map[key], key);
  });
}

export function enumForEach<E extends string>(
  enumObj: EnumDescription<E>,
  callback: (key: E, value: string) => void
) {
  (Object.keys(enumObj) as E[]).forEach((key: E) => {
    callback(key, enumObj[key]);
  });
}
export function enumMap<E extends string, T>(
  enumObj: EnumDescription<E>,
  callback: (key: E, value: string) => T
) {
  return (Object.keys(enumObj) as E[]).map((key: E) =>
    callback(key, enumObj[key])
  );
}

export const equalsIgnoreCase = (s1: string, s2: string): boolean => {
  return s1.localeCompare(s2, undefined, { sensitivity: "accent" }) === 0;
};

export const getRankString = (rank: number): string => {
  if (rank > 10 && rank < 20) return `${rank}th`;
  const lastDigit = rank % 10;
  switch (lastDigit) {
    case 1:
      return `${rank}st`;
    case 2:
      return `${rank}nd`;
    case 3:
      return `${rank}rd`;
    default:
      return `${rank}th`;
  }
};

export const getShortenedBook = (bookName: string) => {
  let result = bookName
    .replace("First", "1")
    .replace("Second", "2")
    .replace("Thessalonians", "Thess")
    .replace("Corinthians", "Corinth");
  return result;
};

export const containsUppercase = (str: string) => {
  return /[A-Z]/.test(str);
};

export const parseQueryString = (
  search: string,
  variable: string
): string | undefined => {
  const query = search.substring(1);
  const vars = query.split("&");
  for (let i = 0; i < vars.length; i++) {
    const pair = vars[i].split("=");
    if (decodeURIComponent(pair[0]) === variable) {
      return decodeURIComponent(pair[1]).toLowerCase();
    }
  }
  return undefined;
};

export const scrollToId = (id: string): void => {
  // Get the items to work with for scrolling
  const outerItem = document.getElementById("root");
  const innerItem = document.getElementById(id);
  if (outerItem !== null && innerItem !== null) {
    // Scroll to the item, offset by the position of the scroling div itself in the window, and subtract a bit to look better
    window.scrollTo({
      top:
        innerItem.getBoundingClientRect().top -
        outerItem.getBoundingClientRect().top,
      left: 0,
      behavior: "smooth",
    });
  }
};
