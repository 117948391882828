import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { RootStore } from "../../state/store";
import { SettingsProps } from "./";
import { Check } from "../Check/Check";
import { Row } from "../Row";
import { SET_SETTINGS } from "../../state/reducers/settings";
import { SettingsData } from "../../types/settings";
import "./settings.scss";

export function SettingsSelector(props: SettingsProps) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const settings = useSelector((state: RootStore) => state.settings);
  const [editedSettings, setEditedSettings] = useState<SettingsData>(settings);

  return (
    <div className="settings-container">
      <div className="settings-top-sticky">
        <button
          className="clickable"
          style={{ marginLeft: 10, marginBottom: 10 }}
          onClick={() => {
            dispatch({ type: SET_SETTINGS, payload: editedSettings });
            props.onClickBack(editedSettings);
          }}
        >
          Back
        </button>
      </div>
      <div className="settings-scrolling">
        <h2>General</h2>
        <Check
          checked={editedSettings.touchscreen}
          onClick={(checked) =>
            setEditedSettings({ ...editedSettings, touchscreen: checked })
          }
        >
          Touchscreen-Friendly Interface
        </Check>
        <p style={{ fontSize: 12, marginTop: -2 }}>
          Check if you have a touchscreen rather than a keyboard. If you have
          both, take your pick.
        </p>
        <Row>
          <Check
            checked={editedSettings.lightMode}
            onClick={() =>
              setEditedSettings({ ...editedSettings, lightMode: true })
            }
            style={{ width: 150 }}
          >
            Light Mode
          </Check>
          <Check
            checked={!editedSettings.lightMode}
            onClick={() =>
              setEditedSettings({ ...editedSettings, lightMode: false })
            }
            style={{ width: 150 }}
          >
            Dark Mode
          </Check>
        </Row>

        <hr className="separator-line" />
        <h2>{"Quiz Now & Multiplayer Individual"}</h2>
        <Check
          checked={editedSettings.qnBookName}
          onClick={(e) =>
            setEditedSettings({ ...editedSettings, qnBookName: e })
          }
        >
          Display Book Name
        </Check>
        <p style={{ marginBottom: 0 }}>Quizmaster Reading Speed</p>
        <Row style={{ marginBottom: 10 }}>
          <Check
            style={{ width: 120 }}
            checked={editedSettings.qmSpeed === "SLOW"}
            onClick={() =>
              setEditedSettings({ ...editedSettings, qmSpeed: "SLOW" })
            }
          >
            Slow
          </Check>
          <Check
            style={{ width: 120 }}
            checked={editedSettings.qmSpeed === "MEDIUM"}
            onClick={() =>
              setEditedSettings({ ...editedSettings, qmSpeed: "MEDIUM" })
            }
          >
            Medium
          </Check>
          <Check
            style={{ width: 120 }}
            checked={editedSettings.qmSpeed === "FAST"}
            onClick={() =>
              setEditedSettings({ ...editedSettings, qmSpeed: "FAST" })
            }
          >
            Fast
          </Check>
        </Row>
        <Check
          checked={editedSettings.qmPrecise}
          onClick={(e) =>
            setEditedSettings({ ...editedSettings, qmPrecise: e })
          }
        >
          Precise Quizmaster Reading Speed
        </Check>
        <p style={{ fontSize: 12, marginTop: -2 }}>
          Adjusts the speed of the quizmaster based on the number of syllables
          in each word
        </p>
        <hr className="separator-line" />
        <h2>Verse Cards & Question Drill</h2>
        <Check
          checked={editedSettings.vcRandomize}
          onClick={(e) =>
            setEditedSettings({ ...editedSettings, vcRandomize: e })
          }
        >
          Randomize Verses
        </Check>
        <Check
          checked={editedSettings.vcReview}
          onClick={(e) => setEditedSettings({ ...editedSettings, vcReview: e })}
        >
          Review Missed
        </Check>
        <Check
          checked={editedSettings.vcVoiceToText}
          onClick={(e) =>
            setEditedSettings({ ...editedSettings, vcVoiceToText: e })
          }
        >
          Use Voice to Text
        </Check>
        <p style={{ fontSize: 12, marginTop: -2 }}>
          Voice to Text in Verse Cards works best on Chrome on a desktop or
          laptop computer. May also work on Edge, Android, or other browsers or
          devices, but not on iOS. Please ensure your browser has microphone
          access.
        </p>
        <hr className="separator-line" />
        <h2>Scorekeeper</h2>
        <Check
          checked={editedSettings.skAdvanced}
          onClick={(e) =>
            setEditedSettings({ ...editedSettings, skAdvanced: e })
          }
        >
          Advanced Mode
        </Check>
        <p style={{ fontSize: 12, marginTop: -2 }}>
          Will give you more options and stats, but will be slightly more
          difficult to keep score.
        </p>
        <Check
          checked={editedSettings.skIncludeOvertime}
          onClick={(value: boolean) =>
            setEditedSettings({ ...editedSettings, skIncludeOvertime: value })
          }
        >
          Include Overtime in Stats
        </Check>
        {/* <Check
          checked={editedSettings.skDisableGraphs}
          onClick={(value: boolean) =>
            setEditedSettings({ ...editedSettings, skDisableGraphs: value })
          }
        >
          Disable Graphs
        </Check>
        <p style={{ fontSize: 12, marginTop: -2 }}>
          Gives simpler views instead of line graphs
        </p> */}
        <Check
          checked={editedSettings.skCompact}
          onClick={(e: boolean) =>
            setEditedSettings({ ...editedSettings, skCompact: e })
          }
        >
          Compact View
        </Check>
        <p style={{ fontSize: 12, marginTop: -2 }}>Good for Phones</p>
        <button
          className="link"
          onClick={() => {
            dispatch({ type: SET_SETTINGS, payload: editedSettings });
            navigate("/sk/hotkeys");
            window.scrollTo(0, 0);
          }}
        >
          Edit Scorekeeper Hotkeys
        </button>
      </div>
    </div>
  );
}
