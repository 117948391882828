import { useState } from "react";
import {
  NumberInput,
  Selection,
  SimpleNumberInput,
  StringInput,
} from "../../../components/Inputs";
import { Row } from "../../../components/Row";
import { useRequestHandler } from "../../../hooks/requestHandler";
import {
  TEvent,
  TQuiz,
  TQuizzerBox,
  TTeamBox,
  Tournament,
} from "../../../types/tournamentTypes";
import { saveTournamentQuiz } from "../../../state/actions/tournamentActions";

interface Props {
  tournament: Tournament;
  purpose: "CREATE" | "UPDATE";
  event: TEvent;
  quiz?: TQuiz;
  onGoBack: () => void;
  quizzerMap: Record<string, string>;
  teamNames: string[];
}
export function EditTQuizForm(props: Props) {
  const handler = useRequestHandler({
    onSuccess: () => {
      props.onGoBack();
    },
  });
  const [quiz, setQuiz] = useState<TQuiz>(
    props.quiz || {
      quizId: 0,
      site: 0,
      round: 0,
      teams: [],
    }
  );

  return (
    <div className="main-box">
      <button
        className="link"
        onClick={props.onGoBack}
        style={{ padding: 0, marginTop: 10 }}
      >
        Go Back
      </button>
      <NumberInput
        caption="Quiz ID:"
        value={quiz.quizId}
        onChange={(value) => setQuiz({ ...quiz, quizId: value })}
        belowText="Number doesn't matter; just must be unique among quizzes in the event"
      />
      <NumberInput
        caption="Round:"
        value={quiz.round}
        onChange={(value) => setQuiz({ ...quiz, round: value })}
      />
      <NumberInput
        caption="Site:"
        value={quiz.site}
        onChange={(value) => setQuiz({ ...quiz, site: value })}
      />
      <StringInput
        caption="Quizmaster:"
        value={quiz.qm}
        onChange={(value) => setQuiz({ ...quiz, qm: value })}
      />
      <StringInput
        caption="Livestream Link:"
        value={quiz.livestream}
        onChange={(value) => setQuiz({ ...quiz, livestream: value })}
      />
      <StringInput
        caption="Flag"
        value={quiz.flag}
        onChange={(flag) => setQuiz({ ...quiz, flag })}
      />
      <Selection
        caption="Add Team"
        value=""
        values={props.teamNames}
        onChange={(teamName) => {
          const roster = props.tournament.rosters![teamName];
          const newTeam: TTeamBox = {
            teamName,
            index: quiz.teams.length,
            tech: 0,
            e: 0,
            s: 0,
            p: 0,
            quizzers: Object.keys(roster.quizzers).map((qId) => ({
              qId: Number.parseInt(qId),
              p: 0,
              e: 0,
              f: 0,
              t: 0,
            })),
          };
          setQuiz({
            ...quiz,
            teams: [...quiz.teams, newTeam],
          });
        }}
        placeholder="Team"
      />
      <Row>
        {quiz.teams.map((teamBox, index) => {
          const editTeam = (newTeam: TTeamBox) => {
            const newQuiz = {
              ...quiz,
              teams: [...quiz.teams],
            };
            newQuiz.teams[index] = newTeam;
            setQuiz(newQuiz);
          };
          return (
            <div key={teamBox.teamName} style={{ marginRight: 5 }}>
              <h4>{teamBox.teamName}</h4>
              <NumberInput
                caption="Seed"
                value={teamBox.seed}
                onChange={(value) =>
                  editTeam({ ...teamBox, seed: value || undefined })
                }
                placeholder="Seed"
                belowText="Optional - How the team is in this quiz"
              />
              <NumberInput
                caption="Index"
                value={teamBox.index}
                onChange={(value) => editTeam({ ...teamBox, index: value })}
              />
              <NumberInput
                caption="Place"
                value={teamBox.p}
                onChange={(value) => editTeam({ ...teamBox, p: value })}
              />
              <NumberInput
                caption="Score"
                value={teamBox.s}
                onChange={(value) => editTeam({ ...teamBox, s: value })}
              />
              <NumberInput
                caption="Errors"
                value={teamBox.e}
                onChange={(value) => editTeam({ ...teamBox, e: value })}
              />
              <p style={{ fontSize: 12 }}>
                1st Box: Correct (not total points); 2nd Box: Errors
              </p>
              {teamBox.quizzers.map((quizzer, qIndex) => {
                const editQuizzer = (newQuizzer: TQuizzerBox) => {
                  const newQuizzers = [...teamBox.quizzers];
                  newQuizzers[qIndex] = newQuizzer;
                  editTeam({ ...teamBox, quizzers: newQuizzers });
                };

                return (
                  <Row key={quizzer.qId}>
                    <div style={{ width: 200 }}>
                      <span>{props.quizzerMap[quizzer.qId]}</span>
                    </div>
                    <div style={{ marginLeft: 5 }}>
                      <SimpleNumberInput
                        inputStyle={{ width: 50 }}
                        caption="Correct"
                        value={quizzer.p}
                        onChange={(value) =>
                          editQuizzer({ ...quizzer, p: value })
                        }
                      />
                    </div>
                    <div style={{ marginLeft: 5 }}>
                      <SimpleNumberInput
                        inputStyle={{ width: 50 }}
                        caption="Errors"
                        value={quizzer.e}
                        onChange={(value) =>
                          editQuizzer({ ...quizzer, e: value })
                        }
                      />
                    </div>
                  </Row>
                );
              })}
            </div>
          );
        })}
      </Row>
      <div style={{ marginTop: 10 }}>
        <button
          className="clickable"
          onClick={() => {
            handler.runRequest(
              saveTournamentQuiz(
                props.purpose,
                props.tournament.pageId,
                props.tournament.secondaryId,
                props.event.eventName,
                quiz
              ),
              "Saving the quiz...",
              "Quiz saved!"
            );
          }}
        >
          Save Quiz
        </button>
      </div>
      {handler.snackbar}
    </div>
  );
}
