import { useEffect, useState } from "react";
import { Navigate, useLocation, useNavigate, Link } from "react-router-dom";
import { Grid } from "@mui/material";
import { DataTable } from "../../components/DataTable/DataTable";
import { Row } from "../../components/Row";
import { TournamentHeader } from "../../components/TournamentComponents/TournamentHeader";
import { TPageDescription } from "../../components/TournamentComponents/TPageDescription";
import { TournamentText } from "../../components/TournamentComponents/TournamentText";
import { TournamentSidebar } from "../../components/TournamentComponents/TournamentSidebar";
import {
  TournamentLink,
  TournamentPlaceContent,
} from "../../components/TournamentComponents/TournamentLink";
import { TournamentTrivia } from "../../components/TournamentComponents/TournamentTrivia";
import { TournamentFooter } from "../../components/TournamentComponents/TournamentFooter";
import { TournamentDetailDisplay } from "./TournamentDetails";
import {
  CompetitorDetails,
  CurrentTournamentContent,
} from "./CompetitorDetails";
import { useDocumentTitle } from "../../hooks/useDocumentTitle";
import { useDataProvider } from "../../hooks/dataProvider";
import { useKeyedParams } from "../../hooks/useParams";
import {
  getPage,
  setSelectedPage,
} from "../../state/actions/tournamentActions";
import { TournamentPage, TournamentSummary } from "../../types/tournamentTypes";
import { getPageName } from "../../util/tournamentUtils";
import { getDateRangeDisplay } from "../../util/dateUtils";
import "./Tournaments.scss";

interface TournamentPageProps {
  pageId: string;
  secondaryId?: string;
}

export function TournamentPageWrapper() {
  const navigate = useNavigate();
  const location = useLocation();
  const { pageId, secondaryId } = useKeyedParams<TournamentPageProps>();

  const [pageNotLoaded, setPageNotLoaded] = useState<boolean>(false);
  useEffect(() => {
    // The component doesn't unmount when the route changes, so don't continue displaying the "no page" content
    setPageNotLoaded(false);
  }, [pageId, secondaryId]);

  const provider = useDataProvider({
    id: `${pageId}${secondaryId ? `/${secondaryId}` : ""}`.replace(/_/g, " "),
    getId: (tournament) =>
      `${tournament.pageId}${
        tournament.secondaryId ? `/${tournament.secondaryId}` : ""
      }`,
    dispatchAction: setSelectedPage,
    selector: (state) => state.tournaments.selectedPage,
    getData: getPage,
    onGetData: (page) => {
      navigate(
        `/tournaments/${page.pageId}${
          page.secondaryId ? `/${page.secondaryId}` : ""
        }`.replace(/ /g, "_"),
        { replace: true, state: location.state }
      );
    },
    render: (page) => {
      return <TournamentPageComponent selectedPage={page} />;
    },
    onUseFallback: () => {
      if (location.state?.alternatePage && location.state?.redirect !== false) {
        const newState = {
          ...location.state,
          redirectedFrom: { pageId, secondaryId },
        };
        delete newState.alternatePage;
        navigate(
          `/tournaments/${location.state.alternatePage.pageId}${
            location.state.alternatePage.secondaryId
              ? `/${location.state.alternatePage.secondaryId}`
              : ""
          }`.replace(/ /g, "_"),
          { replace: true, state: newState }
        );
      } else {
        setPageNotLoaded(true);
      }
    },
    disableFallback: true,
  });

  if (pageNotLoaded) {
    return (
      <div className="page">
        <h1>
          {getPageName({ pageId, secondaryId } as TournamentPage).replace(
            /_/g,
            " "
          )}
        </h1>
        <p>
          There is no content for this page. If you think there should be,
          please add it below.
        </p>
        <Row style={{ marginTop: 10 }}>
          <button className="back-button" onClick={() => navigate(-1)}>
            Go Back
          </button>
          <Link
            className="clickable"
            to="/tournaments"
            style={{ marginLeft: 10 }}
          >
            Return Home
          </Link>
        </Row>
        <TournamentFooter
          pageId={pageId}
          secondaryId={secondaryId}
          linkText="Request to add page"
        />
      </div>
    );
  }

  return provider.getPage();
}
export function TournamentPageComponent({
  selectedPage,
}: {
  selectedPage: TournamentPage;
}) {
  const location = useLocation();

  const pageName = getPageName(selectedPage);
  useDocumentTitle(pageName + " - BQA Tournaments");

  const getCurrentTeamsContent = () => {
    if (selectedPage.pageType !== "MINISTRY" || !selectedPage.currentTeams)
      return null;

    return (
      <div>
        <h3 style={{ marginTop: 0 }}>Current Teams</h3>
        <Row>
          {selectedPage.currentTeams.local && (
            <div style={{ width: 200 }}>
              <h4 style={{ margin: "5px 0" }}>Local Season</h4>
              {selectedPage.currentTeams.local.map((team) => (
                <p key={team.team}>
                  <TournamentLink
                    pageId={team.teamId || team.team}
                    text={team.team}
                  />
                </p>
              ))}
            </div>
          )}
          {selectedPage.currentTeams.postseason && (
            <div>
              <h4 style={{ margin: "5px 0" }}>Postseason</h4>
              {selectedPage.currentTeams.postseason.map((team) => (
                <p key={team.team}>
                  <TournamentLink
                    pageId={team.teamId || team.team}
                    text={team.team}
                  />
                </p>
              ))}
            </div>
          )}
        </Row>
      </div>
    );
  };

  const getTournamentsListContent = () => {
    if (selectedPage.pageType !== "SERIES") return null;
    return (
      <>
        <h3 style={{ marginTop: 0, maxHeight: "unset" }}>
          Tournaments in the Series
        </h3>
        <DataTable<TournamentSummary>
          style={{ marginBottom: 20 }}
          getKey={(obj) => `${obj.pageId}-${obj.secondaryId}`}
          columns={[
            {
              title: "Tournament",
              property: "name",
              render: (obj) => (
                <TournamentLink
                  pageId={obj.pageId}
                  secondaryId={obj.secondaryId}
                />
              ),
            },
            {
              title: "Date",
              property: "dates",
              render: (obj) => getDateRangeDisplay(obj.startDate, obj.endDate),
              sortFunc: (obj) => obj.startDate,
            },
            {
              title: "Teams",
              property: "teamCount",
              render: (obj) => (obj.isIndividual ? "Indiv." : obj.teamCount),
              sortFunc: true,
            },
            {
              title: "1st",
              property: "place1",
              render: (obj) => (
                <TournamentPlaceContent tournament={obj} index={0} />
              ),
            },
            {
              title: "2nd",
              property: "place2",
              render: (obj) => (
                <TournamentPlaceContent tournament={obj} index={1} />
              ),
            },
            {
              title: "3rd",
              property: "place3",
              render: (obj) => (
                <TournamentPlaceContent tournament={obj} index={2} />
              ),
            },
          ]}
          data={selectedPage.tournaments}
          defaultSort={{
            property: "dates",
            direction: "desc",
          }}
        />
      </>
    );
  };

  if (selectedPage.pageType === "REDIRECT") {
    if (location.state?.redirect !== false) {
      return (
        <Navigate
          to={`/tournaments/${selectedPage.redirectTo.pageId}${
            selectedPage.redirectTo.secondaryId
              ? `/${selectedPage.redirectTo.secondaryId}`
              : ""
          }`.replace(/ /g, "_")}
          state={{
            redirectedFrom: {
              pageId: selectedPage.pageId,
              secondaryId: selectedPage.secondaryId,
            },
          }}
          replace
        />
      );
    } else {
      return (
        <div className="tournament-page">
          <TournamentHeader
            pageName={pageName}
            navigateBack="/tournaments"
            pageId={selectedPage.pageId}
            secondaryId={selectedPage.secondaryId}
          />
          <p>
            {"This page redirects to "}
            <TournamentLink {...selectedPage.redirectTo} />
            {"."}
          </p>
        </div>
      );
    }
  }

  const isTournament = selectedPage.pageType === "TOURNAMENT";
  const isCompetitor =
    selectedPage.pageType === "QUIZZER" || selectedPage.pageType === "TEAM";

  const mainContent = (
    <>
      {getTournamentsListContent()}
      {getCurrentTeamsContent()}
      {isTournament && <TournamentDetailDisplay page={selectedPage} />}
      {isCompetitor && <CompetitorDetails page={selectedPage} />}
      {selectedPage.pageType === "OTHER" && (
        <CurrentTournamentContent page={selectedPage} />
      )}
      {!isTournament && !isCompetitor && (
        <TournamentTrivia page={selectedPage} />
      )}
    </>
  );
  return (
    <div className="tournament-page">
      <TournamentHeader
        pageName={pageName}
        navigateBack="/tournaments"
        pageId={selectedPage.pageId}
        secondaryId={selectedPage.secondaryId}
      />
      {location.state?.redirectedFrom && (
        <p style={{ fontSize: 14, marginBottom: 10 }}>
          {"(Redirected from "}
          <TournamentLink
            {...location.state.redirectedFrom}
            navigateState={{ redirect: false }}
          />
          {")"}
        </p>
      )}
      <TPageDescription page={selectedPage} />
      {selectedPage.additionalData.bio && (
        <TournamentText text={selectedPage.additionalData.bio} />
      )}
      {isTournament ? (
        mainContent
      ) : (
        <Grid container spacing={2}>
          <TournamentSidebar
            page={selectedPage}
            breakpoints={{ xs: 12, sm: 12, md: 4, lg: 4, xl: 2 }}
          />
          <Grid item xs={12} sm={12} md={8} lg={8} xl={10}>
            {mainContent}
          </Grid>
        </Grid>
      )}

      <TournamentFooter page={selectedPage} />
    </div>
  );
}
