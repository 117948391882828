import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootStore } from "../state/store";
import { Grid } from "@mui/material";
import { Ruling } from "../components/Ruling";
import { useDocumentTitle } from "../hooks/useDocumentTitle";
import { shuffle } from "../util/generalUtil";
import { Row } from "../components/Row";
import { Check } from "../components/Check/Check";

interface Reference {
  book: number;
  chapter: number;
  verse: number;
}
type Phases = "BEFOREHAND" | "READY" | "NUMBER" | "GUESS" | "REVIEW";

export function QuestionDrill() {
  useDocumentTitle("Question Drill - Bible Quiz Academy");
  const [mode, setMode] = useState("HARD");
  const [randomized, setRandomized] = useState<Reference[]>([]);
  const [previousVerse, setPreviousVerse] = useState<Reference>();
  const material = useSelector((state: RootStore) => state.material.material);
  const settings = useSelector((state: RootStore) => state.settings);
  const chapters = settings.selectedChapters;
  const [phase, setPhase] = useState<Phases>("BEFOREHAND");
  const [autoCall, setAutoCall] = useState<"" | "CORRECT" | "INCORRECT">("");
  const stats = useRef<{
    totalVerses: number;
    correct: number;
    incorrect: number;
  }>({
    totalVerses: 0,
    correct: 0,
    incorrect: 0,
  });

  useEffect(() => {
    if (material.length === 0) return;
    const allVerses: Reference[] = [];
    material.forEach((book, index) => {
      book.chapters.forEach((chapter, chapterIndex) => {
        if (chapters && chapters[index] && chapters[index][chapterIndex]) {
          chapter.forEach((_, verseIndex) => {
            const chapterFromSettings = chapters[index][chapterIndex];
            const chapterSettings =
              typeof chapterFromSettings === "boolean"
                ? Array(chapter.length).fill(chapterFromSettings)
                : chapterFromSettings;
            if (chapterSettings[verseIndex]) {
              allVerses.push({
                book: index,
                chapter: chapterIndex,
                verse: verseIndex,
              });
            }
          });
        }
      });
    });
    if (settings.vcRandomize) shuffle(allVerses);
    setRandomized(allVerses);
    stats.current.totalVerses = allVerses.length;
  }, [material]);

  const currentReference = randomized[0];
  const currentVerse = currentReference
    ? material[currentReference.book].chapters[currentReference.chapter][
        currentReference.verse
      ]
    : undefined;

  const makeRuling = (rulingType: number) => {
    const newRandomized = [...randomized];
    newRandomized.splice(0, 1);
    if (rulingType === 1 && settings.vcReview)
      newRandomized.push(currentReference);
    setRandomized(newRandomized);
    if (rulingType === 0) stats.current.correct += 1;
    if (rulingType === 1) stats.current.incorrect += 1;
    setPreviousVerse(currentReference);
    setPhase("READY");
  };

  const clickQuestionCount = (count: number) => {
    if (currentVerse) {
      if (
        (count >= 3 && currentVerse.questions.length >= 3) ||
        count === currentVerse.questions.length
      ) {
        // Correct
        if (count >= 1) {
          // Move on
          setPhase("GUESS");
        } else {
          // Auto correct
          makeRuling(0);
          setAutoCall("CORRECT");
        }
      } else {
        // Incorrect
        makeRuling(1);
        setAutoCall("INCORRECT");
      }
    }
  };

  const referenceDisplay = (reference: Reference) => {
    return (
      <p>
        {`${material[reference.book].bookName} ${reference.chapter + 1}:${
          reference.verse + 1
        }`}
      </p>
    );
  };
  const verseDisplay = (
    reference: Reference,
    showVerse: boolean,
    showQuestions: boolean
  ) => {
    return (
      <>
        {referenceDisplay(reference)}
        {phase === "READY" && autoCall && (
          <p style={{ fontSize: 20 }}>
            {autoCall === "CORRECT" ? "Correct!" : "Incorrect"}
          </p>
        )}
        {showVerse && (
          <p style={{ marginBottom: 20 }}>
            {
              material[reference.book].chapters[reference.chapter][
                reference.verse
              ].verse
            }
          </p>
        )}
        {showQuestions && (
          <>
            {material[reference.book].chapters[reference.chapter][
              reference.verse
            ].questions.length === 0 && <p>Quote Only</p>}
            {material[reference.book].chapters[reference.chapter][
              reference.verse
            ].questions.map((question, index) => (
              <p key={index} style={{ margin: 0 }}>
                {question}
              </p>
            ))}
          </>
        )}
      </>
    );
  };
  const getNumberGuess = (count: number, display: string) => {
    return (
      <Grid item>
        <button className="clickable" onClick={() => clickQuestionCount(count)}>
          {display}
        </button>
      </Grid>
    );
  };
  return (
    <div className="page">
      {phase === "BEFOREHAND" && (
        <div>
          <h1>Question Drill</h1>
          <p></p>
          <Grid container style={{ maxWidth: 600 }}>
            <Grid item xs={6} xl={6}>
              <Check checked={mode === "EASY"} onClick={() => setMode("EASY")}>
                Easy Mode
              </Check>
              <p>
                You will be given the reference and verse, and you will figure
                out the questions in the verse.
              </p>
            </Grid>
            <Grid item xs={6} xl={6}>
              <Check checked={mode === "HARD"} onClick={() => setMode("HARD")}>
                Hard Mode
              </Check>
              <p>
                You will be given just the reference, and must figure out all
                the questions in the verse.
              </p>
            </Grid>
          </Grid>
          <Row style={{ marginTop: 10 }}>
            <Link className="back-button" to="/" style={{ marginRight: 10 }}>
              Go Back
            </Link>
            <button className="clickable" onClick={() => setPhase("READY")}>
              Get Started
            </button>
          </Row>
        </div>
      )}
      <div style={{ minHeight: 150, marginBottom: 20 }}>
        {phase === "READY" &&
          previousVerse &&
          verseDisplay(previousVerse, true, true)}
        {currentVerse &&
          ["NUMBER", "GUESS"].includes(phase) &&
          verseDisplay(currentReference, mode === "EASY", false)}
        {phase === "NUMBER" && (
          <>
            <p>How many questions are in this verse?</p>
            <Grid container spacing={2}>
              {getNumberGuess(0, "Quote Only")}
              {getNumberGuess(1, "1")}
              {getNumberGuess(2, "2")}
              {getNumberGuess(3, "3+")}
            </Grid>
          </>
        )}
        {phase === "GUESS" && currentVerse && (
          <>
            <p>{`${currentVerse.questions.length} Question${
              currentVerse.questions.length === 1 ? "" : "s"
            }`}</p>
            <p>Try to figure out all the questions in the verse!</p>
            <button className="clickable" onClick={() => setPhase("REVIEW")}>
              Show Questions
            </button>
          </>
        )}
        {currentVerse &&
          phase === "REVIEW" &&
          verseDisplay(currentReference, true, true)}
        {phase === "REVIEW" && currentVerse && (
          <Ruling
            hideThrowout
            onClick={(ruling) => {
              makeRuling(ruling);
              setAutoCall("");
            }}
            style={{ marginTop: 10 }}
          />
        )}
      </div>
      {phase === "READY" && randomized.length > 0 && (
        <button className="clickable" onClick={() => setPhase("NUMBER")}>
          Next Verse
        </button>
      )}
      {phase === "READY" && (
        <div style={{ marginTop: 10 }}>
          <p
            style={{ margin: 0 }}
          >{`Total Verses: ${stats.current.totalVerses}`}</p>
          <p style={{ margin: 0 }}>{`Verses Remaining: ${
            stats.current.totalVerses - stats.current.correct
          }`}</p>
          <p style={{ margin: 0 }}>{`Correct: ${stats.current.correct}`}</p>
          <p style={{ margin: 0 }}>{`Incorrect: ${stats.current.incorrect}`}</p>
          <div style={{ marginTop: 15 }}>
            <Link className="back-button" to="/">
              Go Back
            </Link>
          </div>
        </div>
      )}
    </div>
  );
}
