import { useState } from "react";
import { TEvent } from "../../../types/tournamentTypes";
import {
  DateInput,
  NumberInput,
  StringInput,
} from "../../../components/Inputs";
import { Row } from "../../../components/Row";
import { useRequestHandler } from "../../../hooks/requestHandler";
import {
  createTournamentEvent,
  updateTournamentEvent,
} from "../../../state/actions/tournamentActions";

export function EditTournamentEvent(props: {
  isCreate: boolean;
  onGoBack: () => void;
  pageId: string;
  secondaryId: string;
  event: TEvent;
}) {
  const handler = useRequestHandler({
    onSuccess: () => {
      props.onGoBack();
    },
  });
  const [thisEvent, setEvent] = useState({ ...props.event });
  const getBlackLine = () => {
    let line = "";
    thisEvent.blackLine?.forEach((item, index) => {
      if (index > 0) line += ",";
      line += isNaN(item) ? "" : item;
    });
    return line;
  };
  const [blackLine, setBlackLine] = useState(getBlackLine());
  const getRoundNames = () => {
    let names = "";
    if (thisEvent.roundNames) {
      Object.keys(thisEvent.roundNames).forEach((key, index) => {
        if (index > 0) names += ",";
        names += `${key}:${thisEvent.roundNames![key]}`;
      });
    }
    return names;
  };
  const [roundNames, setRoundNames] = useState(getRoundNames());

  return (
    <div>
      {props.isCreate && (
        <StringInput
          caption="Event Name"
          value={thisEvent.eventName}
          onChange={(eventName) => setEvent({ ...thisEvent, eventName })}
        />
      )}
      <DateInput
        value={thisEvent.date}
        onChange={(date) => setEvent({ ...thisEvent, date })}
        caption="Date"
      />
      <NumberInput
        caption="Order"
        value={thisEvent.order}
        onChange={(order) => setEvent({ ...thisEvent, order })}
      />
      <StringInput
        caption="Description"
        value={thisEvent.description}
        onChange={(description) => setEvent({ ...thisEvent, description })}
      />
      <StringInput
        caption="Black Line"
        belowText="Deliminate sites with commas"
        value={blackLine}
        onChange={setBlackLine}
      />
      <StringInput
        caption="Round Names"
        belowText="Deliminate rounds and names with colons and separate rounds with commas"
        value={roundNames}
        onChange={setRoundNames}
      />
      <Row style={{ marginTop: 10 }}>
        <button className="back-button" onClick={props.onGoBack}>
          Cancel
        </button>
        <button
          className="clickable"
          style={{ marginLeft: 10 }}
          onClick={() => {
            const resultEvent = {
              ...thisEvent,
            };

            // Black Line
            if (!blackLine) {
              delete resultEvent.blackLine;
            } else {
              const sites = blackLine.split(",");
              const newBlackLine = sites.map((item) => Number.parseInt(item));
              resultEvent.blackLine = newBlackLine;
            }

            // Round Names
            if (!roundNames) {
              delete resultEvent.roundNames;
            } else {
              const sites = roundNames.split(",");
              const newNames: Record<string, string> = {};
              sites.forEach((site) => {
                const parts = site.split(":");
                if (parts.length === 2) newNames[parts[0]] = parts[1];
              });
              resultEvent.roundNames = newNames;
            }

            const action = props.isCreate
              ? createTournamentEvent(
                  props.pageId,
                  props.secondaryId,
                  resultEvent
                )
              : updateTournamentEvent(
                  props.pageId,
                  props.secondaryId,
                  resultEvent
                );
            handler.runRequest(action, "Saving the event...", "Event saved.");
          }}
        >
          Save Event Details
        </button>
      </Row>
    </div>
  );
}
